<template>
  <Layout :tituloPagina="'Contratos Cancelados'">
    <div class="mt-4 mb-4 text-right"></div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="municipio"
            v-model="columnas.municipio"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="municipio">Municipio</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="colonia"
            v-model="columnas.colonia"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="colonia">Colonia</label>
        </div>
        &nbsp; 
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="servicio"
            v-model="columnas.servicio"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="servicio">Servicio</label>
        </div>
        &nbsp; 
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="fechaInstalacion"
            v-model="columnas.fecha_instalacion"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="fechaInstalacion">Fecha instalación</label>
        </div>
        &nbsp; 
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="fechaCancelacion"
            v-model="columnas.fecha_cancelacion"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="fechaCancelacion">Fecha cancelación</label>
        </div>
      </div>
      <div class="col-md-6 text-right">
        <router-link
          class="btn btn-secondary"
          :to="{ name: 'inicio' }"
        >
          <i class="bx bx-grid-alt"></i>
          Inicio
        </router-link>
      </div>
    </div>
    <br>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover align-middle table-nowrap">
            <thead>
              <tr>
                <th colspan="5">
                  Mes inicial: 
                  <strong class="text-success text-capitalize">
                    {{ formatoFecha(mesInicial, 'MM-YYYY', 'MMMM YYYY') }}
                  </strong>
                </th>
                <th colspan="4" class="text-right">
                  Mes final: 
                  <strong class="text-success text-capitalize">
                    {{ formatoFecha(mesFinal, 'MM-YYYY', 'MMMM YYYY') }}
                  </strong>
                </th>
              </tr>
              <tr>
                <th class="text-right" style="width: 60px">ID contrato</th>
                <th>Cliente</th>
                <th v-show="columnas.municipio">Municipio</th>
                <th v-show="columnas.colonia">Colonia</th>
                <th style="width: 150px">CPE asignado</th>
                <th v-show="columnas.servicio">Servicio</th>
                <th v-show="columnas.fecha_instalacion" class="text-center" style="width: 180px">Fecha de intalación</th>
                <th v-show="columnas.fecha_cancelacion" class="text-center" style="width: 180px">Fecha de cancelacion</th>
                <th class="text-center" style="width: 70px">Estado</th>
                <th class="text-center" style="width: 110px">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="contrato in contratos" :key="contrato.id">
                <th class="text-left cursor" @click="verContratoCancelado(contrato.id_cliente, contrato.id)">
                  {{ contrato.id }}
                </th>
                <td class="cursor" @click="verTableroCliente(contrato.id_cliente)">
                  {{ contrato.nombre_cliente }} 
                  {{ contrato.aPaterno_cliente }} 
                  {{ contrato.aMaterno_cliente }}
                </td>
                <td v-show="columnas.municipio">
                  {{ contrato.municipio_cliente }}
                </td>
                <td v-show="columnas.colonia">
                  {{ contrato.colonia_cliente }}
                </td>
                <td>
                  <span class="text-danger" v-show="!contrato.id_cpe">
                    CPE no asignado
                  </span>
                  <span class="text-info cursor" 
                    v-show="contrato.id_cpe" 
                    @click="verCpesContratosCancelados(contrato.id_cpe)"
                  >
                    {{contrato.nombre_cpe}}
                  </span>
                </td>
                <td v-show="columnas.servicio" :class="{ 'text-danger': !contrato.nombre_servicio }">
                  {{ contrato.nombre_servicio || 'Sin servicio' }}
                  <sup
                    class="badge badge-pill badge-soft-info"
                    v-show="contrato.id_tipo_renta == 1"
                    title="Renta Mensual Fija"
                  >
                    RMF
                  </sup>
                  <sup
                    class="badge badge-pill badge-soft-warning"
                    v-show="contrato.id_tipo_renta == 2"
                    title="Renta Mensual Variable"
                  >
                    RMV
                  </sup>
                </td>
                <td v-show="columnas.fecha_instalacion" class="text-right">
                  {{ formatoFecha(contrato.fecha_instalacion) }}
                </td>
                <td v-show="columnas.fecha_cancelacion" class="text-right">
                  {{ formatoFecha(contrato.updated_at) }}
                </td>
                <td class="text-right">
                  <span class="badge bg-danger bg-gradient font-size-13" v-show="contrato.cancelado">
                    Cancelado
                  </span>
                </td>
                <td class="text-center">
                  <button 
                    class="btn btn-light btn-sm" 
                    @click="verContratoCancelado(contrato.id_cliente, contrato.id)"
                  >
                    <i class="mdi mdi-eye"></i> Mostrar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarClientesCancelados()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import ContratoSrv from '@/services/ContratoSrv.js'
import Paginador from '@/components/Paginador.vue'
import moment from 'moment'
export default {
  name: 'CancelacionContratos',
  components: {
    Layout,
    Paginador
  },
  data() {
    return {
      baseUrl: window.location.origin,
      contratos: [],
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.cancelacionContratos.por_pagina') || 10),
      total: 0,
      ultima_pagina: 1,
      mesInicial: '',
      mesFinal: '',

      columnas: {
        municipio: false,
        colonia: false,
        servicio: true,
        fecha_instalacion: true,
        fecha_cancelacion: true
      },
    }
  },

  created: function() {
    var self = this
    if (self.$route.query.pagina) self.pagina = parseInt(self.$route.query.pagina)
    if (self.$route.query.por_pagina) self.por_pagina = parseInt(self.$route.query.por_pagina)
    if (self.$route.query.mesInicial) self.mesInicial = self.$route.query.mesInicial
    if (self.$route.query.mesFinal) self.mesFinal = self.$route.query.mesFinal

    self.refrescarClientesCancelados()
    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()
  },

  methods: {
    actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.cancelacionContratos.columnas', JSON.stringify(self.columnas))
    },
    cargarPagina: function(n) {
      var self = this
      self.pagina = n
      self.refrescarClientesCancelados()
    },
    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem('argusblack.cancelacionContratos.columnas')
      
      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },
    formatoFecha(fecha, form_entr='YYYY-MM-DD', form_sali='DD/MM/YYYY') {
      return moment(fecha, form_entr).locale('es-mx').format(form_sali)
    },
    refrescarClientesCancelados: function() {
      var self = this

      self.$router.replace({
        query: {
          pagina: self.pagina,
          por_pagina: self.por_pagina,
          mesInicial: self.mesInicial,
          mesFinal: self.mesFinal
        }
      })

      let params = {
        pagina: self.pagina,
        por_pagina: self.por_pagina,
        orden: 'desc',
        mesInicial: self.mesInicial,
        mesFinal: self.mesFinal
      }

      ContratoSrv.contratosCancelados(params).then(response => {
        localStorage.setItem('argusblack.cancelacionContratos.por_pagina', self.por_pagina)

        let res = response.data
        self.pagina = res.pagina
        self.total = res.total
        self.ultima_pagina = res.ultima_pagina
        self.contratos = res.contratos
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = 'No se pudieron cargar los contratos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    verTableroCliente: function(id) {
      var self = this
      self.$router.push({ name: 'tableroCliente', params: { id_cliente: id } })
    },
    verContratoCancelado: function(id_cliente, id) {
      var self = this
      self.$router.push({ name: 'edicionContrato', params: { id_cliente: id_cliente, id_contrato: id } })
    },
    verCpesContratosCancelados: function(id_cpe) {
      var self = this
      self.$router.push({ name: 'edicionCpe', params: { id: id_cpe } })
    }
  },
}
</script>

<style scoped>
</style>